exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-hair-beauty-js": () => import("./../../../src/pages/hair-beauty.js" /* webpackChunkName: "component---src-pages-hair-beauty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-spa-treatment-js": () => import("./../../../src/pages/spa-treatment.js" /* webpackChunkName: "component---src-pages-spa-treatment-js" */),
  "component---src-pages-wedding-packages-js": () => import("./../../../src/pages/wedding-packages.js" /* webpackChunkName: "component---src-pages-wedding-packages-js" */)
}

